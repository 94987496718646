<script setup>
import { useCommon } from "@/store";

const localePath = useLocalePath();

const getFeature = computed(() => {
  return useCommon().getAllFeatureFlags;
});

const showSubscribePage = computed(() => {
  return getFeature.value["website-subscription-page"];
});
</script>

<template>
  <div class="lets-get-started">
    <div>
      <h2
        class="font-bold text-secondary-400"
        v-text="$t('LETSGETSTARTED')"
      />
      <p
        class="text-secondary-400 mt-4 lg:mt-6"
        v-text="$t('TRYITOUTANDSEEHOWFASTIT')"
      />
      <div class="text-secondary-400 mt-4 lg:mt-2 flex items-center">
        <svg-wallet />
        <span
          class="text-secondary-300 ml-3 text-base leading-5"
          v-text="$t('GETYOURFREEMIUMPLAN')"
        />
      </div>
      <div class="mt-6 lg:mt-8">
        <nuxt-link
          :to="localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })"
        >
          <sc-button
            primary
            :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
          />
        </nuxt-link>
      </div>
    </div>
    <img
      class="hidden lg:block"
      src="/images/lets_get_started.png"
      :alt="$t('LETSGETSTARTED')"
    >
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";
.lets-get-started {
  @apply bg-success-200 py-8 grid grid-cols-1 lg:grid-cols-2 px-4 lg:gap-12 items-center lg:px-20;
  border-radius: rem(20px);
  @media (min-width: 1024px) {
    border-radius: rem(25px);
  }
}
</style>
